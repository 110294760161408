<template>
    <div class="">
        <p>我的下线</p>
        <vant-nav-bar></vant-nav-bar>
        <p v-for="item in person.Info">
             <van-contact-card type="edit" :name="item.uname" :tel="item.phone" :editable="false"></van-contact-card>
        </p>
    </div>
</template>

<script >
import VantNavBar from '@/components/common/vant_nvabar/VantNavBarRegin.vue'
import {getagent} from '../../../network/index'
import {reactive, ref} from 'vue'
export default {
    name: "MyAgent",
    setup() {
         const userId=window.localStorage.getItem("id")
         const person = reactive({})
         //发起网络请求
         console.log(userId)
        //法一、
        //  getagent({"userId":userId}).then(res=> {
        //   //  person.Info = res
        //      console.log(res)
        // })
        //法二、
              getagent(userId).then(res=> {
               person.Info = res
              console.log(res)
         })
        return {
            userId,person
        }
    },
    components: {VantNavBar},
}
</script>

<style scoped>

</style>