import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/bootstrap.css'
import router from '../src/router/index.js'
import '../node_modules/vant/lib/index.css'
import { Uploader, Calendar, Icon, Card, NavBar, List, 
    Button, Form, Field, CellGroup, ActionSheet, 
    DatetimePicker, ContactCard, NoticeBar, Search,ShareSheet,
    Popup,DropdownMenu,DropdownItem,Dialog} from 'vant'
// // 引入piinia
import { createPinia } from 'pinia'
//创建pinia实例
const pinia = createPinia();
const app = createApp(App)
    //挂在rooter
app.use(router)
    // 下拉数据挂载
app.use(List);
app.use(Uploader);
app.use(Icon);
app.use(Card);
app.use(NavBar)
app.use(Button)
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(ActionSheet);
app.use(Calendar);
app.use(DatetimePicker);
app.use(ContactCard);
app.use(NoticeBar);
app.use(Search);
app.use(ShareSheet);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Dialog)
app.use(Popup);
//挂载到vue跟实例
app.use(pinia)
app.mount('#app')